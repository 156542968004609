<template>
    <v-dialog v-model="show" scrollable persistent>
        <v-card class="fill-height" max-height="90vh" fluid>
            <v-toolbar color="success" dark>Listagem de pacotes</v-toolbar>
            <v-card-text class="ma-0 pa-0">
                <v-list v-for="(item, index) in items" :key="index" width="100vw">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-if="item.key">CTE: {{ item.key }}</v-list-item-title>
                            <v-list-item-subtitle>{{ index + 1 }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="item.nFe">nFe: {{ item.nFe }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="item.id">ID: {{ item.id }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="item.ticket">Código barra: {{ item.ticket }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="item.volumeCode">Código Volume: {{ item.volumeCode }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider :key="index" />
                </v-list>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn color="error" @click="$emit('close')">Fechar detalhes</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        }
    },
};
</script>