<template>
    <v-dialog v-model="show" persistent>
        <v-card class="fill-height" fluid>
            <v-toolbar color="success" dark>Detalhes do pedido - {{ item.id }}</v-toolbar>
            <v-card-text class="ma-0 pa-0">
                <v-layout wrap class="ma-12">
                    <v-card color="success" class="mx-auto" max-width="400">
                        <v-card-text class="white--text">
                            <v-flex xs12>
                                <b>Criado na fase:</b> {{ item.createMode }}
                            </v-flex>
                            <v-flex xs12 v-if="item.status">
                                <b>Status:</b> {{ packagesStatus[item.status] }}
                            </v-flex>
                            <v-flex xs12 v-if="item.createdBy">
                                <b>Criado por:</b> {{ item.createdBy }}
                            </v-flex>
                            <v-flex xs12 v-if="item.createdAt">
                                <b>Criado:</b> {{ $moment(item.createdAt).format("DD/MM/YY HH:mm") }}
                            </v-flex>
                            <v-flex xs12 v-if="item.updatedAt">
                                <b>Última atualização:</b> {{ $moment(item.updatedAt).format("DD/MM/YY HH:mm") }}
                            </v-flex>
                            <v-flex xs12 v-if="item.necCreatedAt">
                                <b>Criado(NEC):</b> {{ $moment(item.necCreatedAt).format("DD/MM/YY HH:mm") }}
                            </v-flex>
                            <v-flex xs12 v-if="item.necUpdatedAt">
                                <b>Última atualização(NEC):</b> {{ $moment(item.necUpdatedAt).format("DD/MM/YY HH:mm") }}
                            </v-flex>
                            <v-flex xs12 v-if="item.ticket">
                                <b>Código de barras:</b> {{ item.ticket }}
                            </v-flex>
                            <v-flex xs12 v-if="item.order">
                                <b>Pedido:</b> {{ item.order }}
                            </v-flex>
                            <v-flex xs12 v-if="item.nFe">
                                <b>nFe:</b> {{ item.nFe }}
                            </v-flex>
                            <v-flex xs12 v-if="item.volumeCode">
                                <b>Código Volume:</b> {{ item.volumeCode }}
                            </v-flex>
                            <v-flex xs12 v-if="item.code">
                                <b>Código de terceiros:</b> {{ item.code }}
                            </v-flex>
                        </v-card-text>
                    </v-card>
                    <v-card color="success" class="mx-auto" max-width="400" v-if="item.collectedBy">
                        <v-card-text class="white--text">
                            <v-flex xs12 class="text-center">
                                <b>Coletado</b>
                            </v-flex>
                            <v-flex xs12>
                                <b>Data:</b> {{ $moment(item.collectedDate).format("DD/MM/YY HH:mm") }}
                            </v-flex>
                            <v-flex xs12>
                                <b>Coletado por:</b> {{ item.collectedBy }}
                            </v-flex>
                            <v-flex xs12>
                                <b>Romaneio:</b> {{ item.checklistCollect }}
                            </v-flex>
                        </v-card-text>
                    </v-card>
                    <v-card color="success" class="mx-auto" max-width="400" v-if="item.receivedBy">
                        <v-card-text class="white--text">
                            <v-flex xs12 class="text-center">
                                <b>Recebido</b>
                            </v-flex>
                            <v-flex xs12>
                                <b>Data:</b> {{ $moment(item.receivedDate).format("DD/MM/YY HH:mm") }}
                            </v-flex>
                            <v-flex xs12>
                                <b>Recebido por:</b> {{ item.receivedBy }}
                            </v-flex>
                            <v-flex xs12>
                                <b>Romaneio:</b> {{ item.checklistReceive }}
                            </v-flex>
                            <v-flex xs12 v-if="item.driverCollectPack">
                                <b>Motorista:</b> {{ item.driverCollectPack }}
                            </v-flex>
                            <v-flex xs12 v-if="item.driverCollectPackExternal">
                                <b>Motorista tercerizado:</b> {{ item.driverCollectPackExternal }}
                            </v-flex>
                        </v-card-text>
                    </v-card>
                    <v-card color="success" class="mx-auto" max-width="500" v-if="item.key">
                        <v-card-text class="white--text">
                            <v-flex xs12 class="text-center">
                                <b>Integração NEC</b>
                            </v-flex>
                            <v-flex xs12>
                                <b>Integração:</b> {{ item.integrationControl }}
                            </v-flex>
                            <v-flex xs12>
                                <b>Chave:</b> {{ item.key }}
                            </v-flex>
                            <v-flex xs12>
                                <b>Enviado (coleta):</b> {{ item.sendCollected ? "Sim" : "Não" }}
                            </v-flex>
                            <v-flex xs12>
                                <b>Enviado (recebimento):</b> {{ item.sendReceived ? "Sim" : "Não" }}
                            </v-flex>
                        </v-card-text>
                    </v-card>
                    <v-card color="success" class="mx-auto mt-2" max-width="400" v-for="(returnItem, index) in item.returnList" :key="index">
                        <v-card-text class="white--text">
                            <v-flex xs12 class="text-center">
                                <b>Insucesso</b>
                            </v-flex>
                            <v-flex xs12>
                                <b>Data:</b> {{ $moment(returnItem.date).format("DD/MM/YY HH:mm") }}
                            </v-flex>
                            <v-flex xs12>
                                <b>Recebido por:</b> {{ returnItem.createdBy }}
                            </v-flex>
                            <v-flex xs12>
                                <b>Romaneio:</b> {{ returnItem.checklist }}
                            </v-flex>
                            <v-flex xs12 v-if="returnItem.driver">
                                <b>Motorista:</b> {{ returnItem.driver }}
                            </v-flex>
                            <v-flex xs12 v-if="returnItem.driverExternal">
                                <b>Motorista tercerizado:</b> {{ returnItem.driverExternal }}
                            </v-flex>
                            <v-flex xs12>
                                <b>Enviado (devolução):</b> {{ item.sendDevolution ? "Sim" : "Não" }}
                            </v-flex>
                        </v-card-text>
                    </v-card>
                </v-layout>
            </v-card-text>
            <v-divider />
            <v-card-actions class="justify-end">
                <v-btn color="error" @click="$emit('close')">Fechar detalhes</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import packagesStatus from "@/assets/json/packagesStatus.json";

export default {
    props: { 
        show: {
            type: Boolean,
            required: true,
        },
        item : {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            packagesStatus
        };
    },
};
</script>