<template>
    <v-autocomplete :value="value" @change="$emit('change', $event)" :items="users" :filter="customFilter" item-value="id" item-text="name" :label="label" :multiple="multiple"
        deletable-chips small-chips>
        <template v-slot:item="data">
            <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    name: "UserSelect",

    props: {
        value: {
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: "Usuário",
        },
        filter: {
            type: String,
        },
        endpoint: {
            type: String,
        },
    },

    data() {
        return {
            users: null,
        };
    },

    methods: {
        customFilter(item, queryText) {
            const searchText = queryText.toLowerCase();
            return (item.name && item.name.toLowerCase().indexOf(searchText) > -1) || (item.email && item.email.toLowerCase().indexOf(searchText) > -1);
        },
        getUsers() {
            this.$http
                .get(this.endpoint ? this.endpoint : `users/${this.filter}`)
                .then((result) => {
                    if (result) {
                        this.users = result;
                    }
                })
                .catch(() => {
                    this.users = null;
                });
        },
    },

    mounted() {
        this.getUsers();
    },
};
</script>